import React from "react"
import styled from 'styled-components'

import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"

import { colors } from "../../settings"

const Box = styled.div`
    display: flex;
    padding: 15px;
    margin-bottom: 10px 0px;
    border: 2px solid ${colors.red};
    color: black;
    border-radius: 3px;

    svg {
      margin-right: 5px;
      color: ${colors.red};
    }
`

const ErrorBox = (props) => {
  return (
    <Box style={props.style}>
        <FontAwesomeSvgIcon icon={faExclamationCircle} />
        {props.children}
    </Box>
  )
}

export default ErrorBox


import React from "react"
import styled from "styled-components"
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';

const Fieldset = styled.fieldset`
    border: ${props => props.simple ? 0:1}px solid #ccc;
    border-radius: 3px;
    padding: 0px;
    width: ${props => props.wide ? "100%":"auto"};
    text-align: left;

    > div {
        display: flex;
        align-items: center;
        margin-top: ${props => props.small ? -2:-8}px;
    }

    padding: ${props => props.small ? 2:8}px ${props => props.small ? 4:8}px;
    padding-bottom: ${props => props.label ? props.small ? "2px":"8px" : "0px"};
    padding-left: ${props => props.simple ? "0px" : props.small ? "4px":"8px"};

    legend {
        font-size: ${props => props.small ? 85:100}%;
    }

    &.error {
        border-color: red;

        legend {
            color: red;
        }
    }
`

export default (props) => {

  return (
    <Fieldset label={props.label} simple={props.simple} small={props.small} wide={props.wide} className={props.error ? "error":""}>
    {props.label && 
        <legend style={{fontWeight: props.labelBold ? "bold":"normal"}}>
            {props.label}
            {props.required && <span style={{display: "inline-block", color:"red", marginLeft: "5px"}}>*</span>}
        </legend>
    }
    <div>
        {props.startIcon && 
            <FontAwesomeSvgIcon icon={props.startIcon} fixedWidth />
        }
        {props.children}
    </div>
  </Fieldset>
)};

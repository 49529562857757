import React from "react"
import styled from 'styled-components'
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';

const MyPageHeader = styled.div`
    display: flex;
    align-items: center;
    font-family: "Roboto Slab", "Helvetica", "Arial", sans-serif;

    > svg {
        margin-right: 10px;
        color: #ccc;
    }
`
const Header = styled.h1`
    font-size: 30px;
    line-height: 1em;

    small {
        font-weight: normal;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    }
`
const SubHeader = styled.div`
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 14px;
    font-weight: light;
    margin: 0px 0px 10px 0px;
    color: #555;
`

const PageHeader = (props) => {
  return (
        <MyPageHeader>
            { props.icon && 
                <FontAwesomeSvgIcon icon={props.icon} size="3x" />
            }
            <div>
                <Header style={{margin: props.icon ? "10px 0px 5px 0px":" 0px 0px 10px 0px"}}>
                    {props.title}
                    {props.smalltitle && <small>{props.smalltitle}</small>}
                </Header>
                {props.subtitle && <SubHeader dangerouslySetInnerHTML={{__html: props.subtitle}} />}
            </div>
        </MyPageHeader>
    )
}

export default PageHeader
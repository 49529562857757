import React from "react"
import styled from "styled-components"

import Field from "./Field"

const Select = styled.select`
    flex: 1; 
    padding: 6px;
    border: 0px;
    font-size: 18px;
    background-color: white;
    width: 100%;
`

function SelectField(props) {
    return (
        <Field {...props}>
            <Select 
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                {...props}
            >
                {(!props.required || props.allowBlank) && 
                    <option></option>
                }
                { props.options.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </Select>
            {props.children}
        </Field>
    );
}

export default SelectField
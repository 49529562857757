import React, { useEffect, useState, useContext } from "react"
import queryString from 'query-string'

import AccountPage from "../../components/layout/AccountPage"
import SuccessBox from "../../components/boxes/SuccessBox"

import { UserContext } from "../../state/UserContext"
import { postData } from "../../services/server"

const ConfirmPage = () => {
    const userContext = useContext(UserContext);

    const params = queryString.parse(typeof window !== `undefined` ? window.location.search : "");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(
        () => {
            setLoading(true);

            const params = queryString.parse(typeof window !== `undefined` ? window.location.search : "")

            postData("/api/users/confirm", {uid: params.uid},
                (result) => {
                    userContext.login(result)
                },
                (error, status) => {
                    if (status && status == 401)
                        setError("Your confirmation email has expired.")
                    else
                        setError("An error occured while confirming your account.");
                },
                () => {
                    setLoading(false);
                }
            );           
        }, 
        []
    ); 

    return (
        <AccountPage page="confirm" title="Account Confirmation" loading={loading} error={error}>
            <SuccessBox>Account Confirmed!</SuccessBox>
        </AccountPage>
    )
}

export default ConfirmPage

import React from "react"
import styled from 'styled-components'
import { Link } from "gatsby"

import Container from "./Container"
import Breadcrumb from "./Breadcrumb"
import { devices } from "../../settings"

const Content = styled.div`
    background-color: ${props => props.transparent ? "transparent":"white"};
    padding: ${props => props.padding !== undefined ? props.padding : 10}px;

    max-width: ${props => props.simple ? "600px": "none"};
    text-align: ${props => props.center ? "center": "left"};
    margin-left: ${props => (props.simple && props.center) ? "auto": "0px"};
    margin-right: ${props => (props.simple && props.center) ? "auto": "0px"};

    @media ${devices.mobile} {
        padding: 10px;
    } 
`

const Page = (props) => {

  return (
        <>
            {props.breadcrumb && 
                <Container>
                    <Breadcrumb 
                        items={props.breadcrumb} 
                    />
                </Container>
            }
            <Container {...props.containerProps}>
                <Content {...props}>
                    { props.children }
                </Content>
            </Container>
        </>
    )
}

export default Page
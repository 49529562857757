import React from "react"
import { Link } from "gatsby"
import styled from 'styled-components'

import { colors, devices } from "../../settings"
import { titleCase } from "../../utilities/utils"

const Items = styled.div`
    margin: 0px 0px 15px 0px;
    padding: 5px;
    font-size: 12px;
    color: #666;

    @media ${devices.laptop} {
        padding-left: 10px;
    } 
    @media ${devices.mobile} {
        padding-left: 10px;
        padding-top: 0px;
        margin-bottom: 5px;
    } 

    ul {
        margin: 0px;
        padding: 0px;
        list-style-type: none;

        display: flex;
        flex-flow: wrap;

        li {
            white-space: nowrap;

            a {
                color: #666;
                text-decoration: underline;
                white-space: nowrap;

                &:after {
                    display: inline-block;
                    content: '>';
                    margin: 0 .6em;
                    color: #959fa5;
                }
            }
        }
        li:last-child {
            color: black;

            a {                
                &:after {
                    content: ''
                }
            }
        }
    } 
`

const Breadcrumb = (props) => {
  return (
        <Items style={props.style}>
            <ul>
            {props.items.filter(p => p.title).map((page, index) => (
                <li key={index}>
                    {
                    page.url ?
                        <Link to={page.url}>{page.title}</Link>
                    :
                        titleCase(page.title)
                    }
                </li>
            ))}
            </ul>
        </Items>
    )
}

export default Breadcrumb
import React, { useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import styled from "styled-components"
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { isMobile } from 'react-device-detect';
import { Location } from '@reach/router';

import SelectField from "../fields/SelectField"
import { colors, devices } from "../../settings"

const MenuHeader = styled.h3`
   padding: 8px;
   margin: 0px;
   font-weight: bold;
   color: black;
   font-family: "Roboto Slab", "Helvetica", "Arial", sans-serif;

   &.active {
    background-color: ${colors.lightGrey};
   }

   @media ${devices.mobile} {
    font-size: 30px;
    margin: 0px 0px 10px auto;
    text-align: center;

    &.active {
        background-color: transparent;
    }
   }
`
const MenuSubHeader = styled.div`
    margin: 8px 0px 15px 8px;
    font-weight: bold;
    
    @media ${devices.mobile} {
        font-weight: normal;
        margin-bottom: 25px;
        text-align: center;
    }
`
const Menu = styled.div`
    margin: 0px 0px 15px 0px;
    padding: 0px;
    list-style-type: none;
    position: sticky;
    top: 95px;

    @media ${devices.mobile} {
        position: unset;
    }
`
const MenuItem = styled.div`
    padding: 0px;
    font-size: 15px;

    &.active {
        background-color: ${colors.lightGrey};
        opacity: 1 !important;
    }
    &:hover {
        background-color: ${colors.lightGrey};
        opacity: .7;
    }

    a {
        padding: 10px;
        color: black;
        display: block;

        @media ${devices.mobile} {
            padding: 5px 10px;
        }
    }

    svg {
        margin-right: 5px;
    }
`
const Box = styled.div`
    display flex;
    background-color: white;
    align-items: center;
    padding: 10px;
    position: sticky;
    top: 58px;
    margin: 0px -10px;
    border-top: 1px solid #ccc;
    z-index: 997;
    box-shadow: 0px 5px 5px rgba(0,0,0,.35);
    
    @media ${devices.mobile} {
        margin-bottom: 10px;
    }

    label {
        margin: 10px;
    }
    select {
        margin-right: 10px;
    }
`

const SideMenu = (props) => {
  let path = props.location && props.location.pathname ? props.location.pathname : "";

  const [currentUrl, setCurrentUrl] = useState("");

  useEffect(
    () => {
        let matchedUrl =  "";

        if (path.substring(path.length-1, path.length) != "/")
            path = path + "/";
    
        for (const item of props.items) {
            // if (item.url && (path.indexOf(item.url) == 0 || item.url.indexOf(path) == 0)) {
            if (item.url && item.url == path) {
                matchedUrl = item.url;
            }
        } 
        
        setCurrentUrl(matchedUrl);
    }, 
    [props.items, path]
  ); 

  return (
    <>
    <Menu>
        { (props.header && props.url) && 
            <Link to={props.url}>
                <MenuHeader className={props.active==props.name?"active":""}>
                    {props.header}
                </MenuHeader> 
            </Link>
        }
        { (props.header && !props.url) && 
            <MenuHeader className={props.active==props.name?"active":""}>
                {props.header}
            </MenuHeader> 
        }
        { props.subheader && 
            <MenuSubHeader>
                {props.subheader}
            </MenuSubHeader> 
        } 
        { !isMobile &&
            <>
                { props.items.map(item => (
                    <MenuItem key={item.name} className={props.active===item.name?"active":""}>
                        <Link to={item.url}>
                            {item.icon && 
                                <>
                                    <FontAwesomeSvgIcon icon={item.icon} fixedWidth />{' '}
                                </>
                            }
                            {item.title}
                        </Link>
                    </MenuItem>
                ))}
            </>
        }
    </Menu>
    { isMobile && 
        <Box>
            <SelectField 
                label="Viewing"
                wide
                value={currentUrl}
                options={props.items.map(item => {
                    return {
                        value: item.url,
                        label: item.title
                    }
                })}
                onChange={(e) => {
                    navigate(e.target.value);
                }}
            />
        </Box>    
    }
    
    </>
  )
}

export default props => (
    <Location>
      {locationProps => <SideMenu {...locationProps} {...props} />}
    </Location>
  );
import React, { useEffect, useContext } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faUser, faLock } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from 'react-device-detect';

import Page from "./Page"
import PageHeader from "./PageHeader"
import Breadcrumb from "./Breadcrumb"
import SEO from "../seo"
import Layout2Columns from "./Layout2Columns";
import Loading from "../Loading"
import SideMenu from "./SideMenu"
import ErrorBox from "../boxes/ErrorBox"

import { UserContext } from "../../state/UserContext"
import { CartContext } from "../../state/CartContext"
import { colors, devices } from "../../settings"

const PageGrid = styled.div`
    background-color: ${colors.lightGrey};
    padding: 15px !important;

    > div.overflow {
        max-height: calc(1vh - 145px);
        overflow: auto;
    }

    @media ${devices.mobile} {
        padding: 10px;
    }
`

const AccountPage = (props) => {
  const userContext = useContext(UserContext);
  const cartContext = useContext(CartContext);

  const menuItems = []

  if (userContext.email && userContext.email.length) {
    menuItems.push({name: "profile", title: "Edit Your Profile", url: "/account/profile"});
    menuItems.push({name: "signs", title: "My Sign Designs", url: "/account/signs"});
    menuItems.push({name: "projects", title: "My Custom Projects", url: "/account/projects"});    
    menuItems.push({name: "coupons", title: "My Coupons", url: "/account/coupons"});
    menuItems.push({name: "orders", title: "My Order History", url: "/account/orders"});
    
    if (!userContext.isAuthenticated()) {
      menuItems.splice(2, 0, {name: "protect", title: "Protect Your Designs", url: "/account/protect", icon: faLock});
    }
  }
  else {
    menuItems.push({name: "account", title: "Your Account", url: "/account"});
  }

  useEffect(
        () => {
            if (props.secure && props.secure == "registered" && !userContext.isAuthenticated()) {
                navigate("/account/register?page=" + props.page);
            }
        }, 
        []
  );

  function onLogout(e) {
    e.preventDefault();
    userContext.logout();
    cartContext.deleteCart();
    navigate("/account");
  }

  return (
    <>
      <SEO page={props.page} title={props.title} />
      <Page padding={0}>
        <> 
            {isMobile && 
              <SideMenu
                  header="Your Account"
                  subheader={
                      userContext.isAuthenticated() ?
                          <div>
                            Hello {userContext.profile.first_name}
                          </div>
                      :
                          ""
                  }
                  active={userContext.email.length == 0 ? "account" : props.page}
                  items={menuItems}
              />
            }

            { props.breadcrumb && 
                <Breadcrumb 
                    items={props.breadcrumb} 
                />
            }

            <Layout2Columns align="top" padding="0" left={isMobile ? 100:30} right={70}>
                {!isMobile && 
                  <div>
                      <SideMenu
                          header="Your Account"
                          subheader={
                              userContext.isAuthenticated() ?
                                  <div>
                                    <FontAwesomeSvgIcon icon={faUser} />{' '}
                                    {userContext.profile.first_name} {userContext.profile.last_name}
                                    
                                    <div>
                                      <small><a href="#" onClick={onLogout}>Logout</a></small>
                                    </div>
                                  </div>
                              :
                                  ""
                          }
                          active={userContext.email.length == 0 ? "account" : props.page}
                          items={menuItems}
                      />
                  </div>
                }
                <PageGrid>
                    <div className={props.overflow ? "overflow":""}>

                        { props.title && 
                            <PageHeader 
                                icon={props.icon}
                                title={props.title} 
                                subtitle={props.subtitle} 
                            />
                        }
                        { props.loading ? 
                            <Loading />
                            :
                            props.error ?
                                <ErrorBox>{props.error}</ErrorBox>
                                :
                                props.children
                        }
                    </div>
                </PageGrid>
            </Layout2Columns>
        </>
      </Page>
    </>
  )
}

export default AccountPage
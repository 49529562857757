import React from "react"
import styled from 'styled-components'

import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"

import { colors } from "../../settings"

const Box = styled.div`
  display: flex;
  padding: 25px;
  margin: 10px 0px;
  border: 2px solid ${colors.green};
  color: black;
  border-radius: 3px;
  align-items: center;
  font-size: 20px;
  font-weight: 300;
  background-color: white;

  svg {
      margin-right: 10px;
      color: ${colors.green};
  }

  > div {
    flex: 1
  }
`

const SuccessBox = (props) => {
  return (
    <Box>
        <FontAwesomeSvgIcon icon={faCheckCircle} size="3x" />
        <div>{props.children}</div>
    </Box>
  )
}

export default SuccessBox
